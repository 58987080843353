import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import ServiceChargeForm from 'src/sections/sales/forms/service-charge-form';
import NegotiationApprovalSection from 'src/sections/sales/negotiation-approval';
import ServiceChargeApprovalSection from 'src/sections/sales/service-charge-approval';

// Lazy load sections

const Operations = lazy(() => import('src/sections/operations'));
const Agreement = lazy(() => import('src/sections/branch/pages/takeover-agreement'));
const KycApprovalComponent = lazy(() => import('src/sections/kyc'));
const UpdateRegion = lazy(() => import('src/sections/regions/update'));
const CreateRegion = lazy(() => import('src/sections/regions/create'));
const AssayerValuation = lazy(() => import('src/sections/components/tables/gold-assayer'));
const CreateLead = lazy(() => import('src/sections/telesales/forms/create_lead'));
const Leads = lazy(() => import('src/sections/components/tables/telesales/lead-tables'));
const CustomerDetails = lazy(() => import('src/sections/branch/details'));
const CustomerKyc = lazy(() => import('src/sections/components/tables/kyc/index'));
const Invoice = lazy(() => import('src/sections/branch/pages/customer-invoice/invoice'));
const Followup = lazy(() => import('src/sections/components/tables/telesales/followup-tables'));
const Finance = lazy(() => import('src/sections/finance/final-payments'));
const EstimationForm = lazy(() => import('src/sections/gold-assayer/index'));
const TakeoverEstimations = lazy(() => import('src/sections/branch/pages/takeover-estimations'));
const ReleaseInvoice = lazy(() => import('src/sections/finance/release-payment-invoice'));
const SalesNegotiationForm = lazy(() => import('src/sections/sales/forms/negotiation-form'));
const QuotationApproval = lazy(() => import('src/sections/operations/forms/quotation-approval'));
const ReleaseApprovals = lazy(() => import('src/sections/sales/release-approvals'));
const ReleasePaymentsTable = lazy(() => import('src/sections/finance/release-payments'));
const SalesHeadEstimations = lazy(() => import('src/sections/sales/goldrate-approval'));
const ReleaseApprovalForm = lazy(() => import('src/sections/sales/forms/release-approval-form'));
const PaymentApprovalForm = lazy(() => import('src/sections/sales/forms/payment-approval-form'));
const GoldRateApprovalForm = lazy(() => import('src/sections/sales/forms/gold-rate-approval-form'));
const ReleasePaymentApprovalSection = lazy(
  () => import('src/sections/sales/payments-approval')
);
const Quotations = lazy(() => import('src/sections/branch/pages/quotations'));

// Lazy load pages
const IndexPage = lazy(() => import('src/pages/dashboard'));
const Customer = lazy(() => import('src/sections/components/tables/branch'));
const RegionsView = lazy(() => import('src/sections/regions'));
const GoldRate = lazy(() => import('src/pages/dashboard/live-gold-rate'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        {/* <AccessGuard> */}
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
        {/* </AccessGuard> */}
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: 'regions',
        element: <RegionsView />,
      },
      {
        path: 'update/:region_id',
        element: <UpdateRegion />,
      },
      {
        path: 'create',
        element: <CreateRegion />,
      },
      {
        path: 'customers',
        element: <Customer />,
      },
      {
        path: 'valuation/quotations',
        element: <AssayerValuation />,
      },
      {
        path: 'valuation/estimations',
        element: <AssayerValuation />,
      },
      {
        path: 'valuation/estimations/:estimation_id',
        element: <EstimationForm />,
      },

      {
        path: 'negotiations',
        element: <NegotiationApprovalSection />,
      },

      {
        path: 'negotiations/estimations/:estimation_id/transactions/:transaction_id',
        element: <SalesNegotiationForm />,
      },

      {
        path: 'service-charge-approvals',
        element: <ServiceChargeApprovalSection />,
      },

      {
        path: 'service-charge-approvals/estimations/:estimation_id/transactions/:transaction_id',
        element: <ServiceChargeForm />,
      },

      {
        path: 'customers/transaction/:transaction_id/quotation',
        element: <Quotations />,
      },
      {
        path: 'customers/transaction/:transaction_id/invoice',
        element: <Invoice />,
      },
      {
        path: 'customers/transaction/:transaction_id',
        element: <CustomerDetails />,
      },
      {
        path: 'goldrate-approvals',
        element: <SalesHeadEstimations />,
      },
      {
        path: 'payment-approvals',
        element: <ReleasePaymentApprovalSection />,
      },
      {
        path: 'payment-approvals/:estimation_id/transactions/:transaction_id',
        element: <PaymentApprovalForm />,
      },
      {
        path: 'goldrate-approvals/estimations/:estimation_id/transactions/:transaction_id',
        element: <GoldRateApprovalForm />,
      },
      {
        path: 'customers/transaction/:transaction_id/release/:release_id',
        element: <Agreement />,
      },
      {
        path: 'release-approvals',
        element: <ReleaseApprovals />,
      },
      {
        path: 'release-approvals/:estimation_id/transactions/:transaction_id',
        element: <ReleaseApprovalForm />,
      },
      {
        path: 'goldrates',
        element: <GoldRate />,
      },
      {
        path: 'kyc/approvals/:transaction_id',
        element: <KycApprovalComponent />,
      },
      {
        path: 'kyc/approvals',
        element: <CustomerKyc />,
      },
      {
        path: 'accounts',
        element: <Finance />,
      },
      {
        path: 'accounts/release-payments',
        element: <ReleasePaymentsTable />,
      },
      {
        path: 'quotations/approvals',
        element: <Operations />,
      },
      {
        path: 'quotations/approvals/:quotation_id/transactions/:transaction_id',
        element: <QuotationApproval />,
      },
      {
        path: 'telesales/leads',
        element: <Leads />,
      },
      {
        path: 'telesales/leads/calls/:call_id',
        element: <CreateLead />,
      },
      {
        path: 'telesales/leads/:lead_id',
        element: <CreateLead />,
      },
      {
        path: 'telesales/leads/new',
        element: <CreateLead />,
      },
      {
        path: 'telesales/followups/leads/:lead_id',
        element: <CreateLead />,
      },
      {
        path: 'telesales/followup',
        element: <Followup />,
      },
      {
        path: 'release/transaction/:transaction_id',
        element: <TakeoverEstimations />,
      },
      {
        path: 'release/transaction/:transaction_id/quotation',
        element: <Quotations />,
      },
      {
        path: 'payments/:paymentId/:transactions/:transaction_id',
        element: <ReleaseInvoice />,
      },
    ],
  },
];
